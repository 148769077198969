.dropdown,
.dropup{
    .dropdown-menu{
      .dropdown-item.active{
        background-color: inherit;
        color: inherit;
        text-decoration: inherit;
      }
    }
}
ngb-datepicker {
  border-radius: $datepicker-border-radius !important;
  direction: ltr !important;


  &,
  & * {
    outline: none;
  }
  &-rtl {
      direction: rtl;
      &.dropdown-menu {
          left: auto;
      }
      table tr td span {
          float: right;
      }
  }
  top: 0!important;
  left: 0!important;
  padding: $datepicker-dropdown-padding!important;
  @include box-shadow($dropdown-box-shadow);

  .bg-light {
    background: transparent !important;
  }

  &:focus {
    outline: none !important;
  }
  .ngb-dp-header {
    border-bottom: none !important;

    ngb-datepicker-navigation {
      color: $primary;

      .ngb-dp-arrow{
        justify-content: center !important;

        &:hover{
          background: #e9ecef;
          border-radius: .375rem;
        }
      }

      .ngb-dp-arrow-btn {
        margin: 0 !important;
        top: -3px;

        .ngb-dp-navigation-chevron {
          color: $primary;
          width: 6px;
          height: 6px;
        }
      }
      ngb-datepicker-navigation-select {
        .custom-select {
          font-weight: 300;
          color: $primary;
          border: none !important;
          background: transparent !important;
          -webkit-appearance: menulist !important;
          box-shadow: none !important;

          &:hover{
            background: #e9ecef !important;
            border-radius: .375rem;
          }
        }
      }
    }
  }
  .ngb-dp-months {
    ngb-datepicker-month-view {
      .ngb-dp-week {
        border: none;
        .ngb-dp-weekday {
          color: $primary;
          padding-top: 10px;
          opacity: 0.8;
          font-style: normal !important;
          line-height: 20px;
          font-weight: 300;
          width: 36px;
          height: 36px;
          font-size: .875rem;
          transition: all .15s ease;
          text-align: center;
          border: none;
        }
        .small {
          font-size: 14px;
        }
        .ngb-dp-day {
          font-size: .875rem;
          width: 36px;
          height: 36px;
          transition: all .15s ease;
          text-align: center;
          border: none;
          border-radius: 50%;

          .btn-light,
          .custom-day {
            width: 36px;
            height: 36px;
            box-shadow: none;
            color: #525f7f;
            border-radius: 50%;
            align-items: center;
            display: grid;

            &:hover,
            &:active {
              box-shadow: none;
              border: none;
              background-color: transparent;
            }

            &.faded{
              border-radius: 0;
            }
            &.range{
              color: $white;
              &:hover{
                background: #5e72e4;
              }
            }
            &.range-start{
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            &.range-end{
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
          &:hover,
          &:active {
            box-shadow: none;
            border: none;
            background-color: transparent;
          }
          .text-muted {
            color: #adb5bd;
          }
        }
      }
    }
  }
}
.datepicker-container {
  input option {
    display: none;
  }
}

.custom-day.range{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: #fff;
  background: #5e72e4;
  box-shadow: none;
  font-size: .875rem;
  width: 36px;
  height: 36px;
  transition: all .15s ease;
  text-align: center;
  border: none;
  border-radius: 50%;

  &.faded{
    border-radius: 0;
  }
}

@each $color, $value in $theme-colors {

  .bg-modal-#{$color}{
    .modal-content{
        background-color: $value !important;
    }
}

  .dropdown-item{
    &.dropdown-item-#{$color}{
      &:hover{
        @include button-variant($value, $value);
      }
      &.active, &:active{
        background-color: $value !important;
        @include box-shadow($btn-active-box-shadow);
      }
    }
  }
}

.dropdown-toggle{
  &.no-toggle{
    &::after{
      display: none;
    }
  }
}