.auth-form {
    min-width: 340px!important;
    max-width: 480px!important;
}



.auth-logo {
    height: 90px;
}

.line-height-1{
    line-height: 1;
}

label{
    font-size: 12px;
}

.echarts{
    z-index: 0;
}