/*
.ngx-pagination {
    padding-left: 0px !important;
}

.ngx-pagination .current,
.btn-info {
    background: $blue !important;
    border: transparent !important;
    border-radius: 20px !important;
	transition: $transition-base;
	letter-spacing: $btn-letter-spacing;
	will-change: transform;
}

.ngx-pagination .current:hover {
    background: $blue !important;
    border-radius: 20px !important;
    border: transparent !important;
    @include box-shadow($btn-hover-box-shadow);
    transform: translateY($btn-hover-translate-y);
}
*/